<template>
  <div class="ak-text container-layout-custom" style="max-width: 1980px;">
    <q-card flat class="bg-lmsBackground">
        <q-card-section v-html="newsItem.body" />
    </q-card>
  </div>
</template>

<script>

export default {
  props: ['newsId'],
  name: 'NewsPage',
  data () {
    return {
      newsItem: {
        body: ''
      }
    }
  },
  methods: {

  },
  async created () {
    this.$q.loading.show({})
    this.$store.dispatch('news/fetch').then((response) => {
      this.newsItem = this.$store.getters['news/getByUid'](response, this.$route.params.newsId)
      this.$q.loading.hide()
    })
  }
}
</script>
